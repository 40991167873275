import * as React from 'react'

const AboutPage = () => {
    return (
        <main>
            <title>AboutPage</title>
            <h1>AboutPage</h1>
            <p>whatever</p>
        </main>
    )
}

export default AboutPage
